import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ModalWrapper from 'app/modal/modalWrapper';

const { baseCartUrl } = window.inlineCartConfiguration;
const currentUrl = window.location.href;

class AddToCartModal extends Component {
    constructor(props) {
        super();
        this.modalToggle = this.modalToggle.bind(this);
    }

    modalToggle() {
        this.props.onModalCancel();
    }

    render() {
    const contentHtmlModal = `
        <div class="content">
            <div class="row">
                <div class="columns large-12">
                    <div class="columns large-4 small-6"><img src="${this.props.nameImage}" title="${this.props.nameProduct}" /></div>
                    <div class="columns large-8 small-6">
                        <p class="product-meta__tile">${this.props.nameProduct}</p>
                        <p class="product-meta__product-code">Código: ${this.props.sku}</p>
                        <p><strong>Producto añadido a carrito</strong></p>
                    </div>
                </div>
                <div class="columns large-12">
                    <div class="columns large-6 small-6"><a href=${baseCartUrl} class="button button--framed button.medium btn-view-cart">IR AL CARRITO</a></div>
                    <div class="columns large-6 small-6"><a href=${currentUrl} class="button button.medium btn-buy-now">SEGUIR COMPRANDO</a></div>
                </div>
            </div>
        </div>`;
    return (
      <ModalWrapper
        isModalOpen={this.props.isModalOpen}
        maxWidth={this.props.maxWidth}
        isHTMLContent
        modalToggle={this.modalToggle}
        customParentClass="modal-addToBag"
        children={contentHtmlModal}
      >
      </ModalWrapper>
    );
  }
}

AddToCartModal.propTypes = {
  isModalOpen: PropTypes.bool,
  maxWidth: PropTypes.any,
  onModalCancel: PropTypes.func,
  nameProduct: PropTypes.string,
  nameImage: PropTypes.string,
  sku: PropTypes.string
};

export default AddToCartModal;
