import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ProductListComponentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outOfView: false,
      mobileView: false
    };
    this.tl = React.createRef();
    this.stateOutOfViewHandler = this.stateOutOfViewHandler.bind(this);
    this.textTransformer = this.textTransformer.bind(this);
    this.arrCreator = this.arrCreator.bind(this);
    this.setMobileView = this.setMobileView.bind(this);
  }

  componentDidMount() {
    const maxWidth = (window.innerWidth || document.documentElement.clientWidth);
    const mobileView = 600;
    if (maxWidth > mobileView) {
      const elementOutOfView = this.tl.current;
      const bounding = elementOutOfView.getBoundingClientRect();
      if (bounding.width > (maxWidth - bounding.x)) {
        this.stateOutOfViewHandler(bounding.width);
        this.props.modalOutOfBoundHandler();
      }
    } else {
      this.setMobileView();
    }
  }

  setMobileView() {
    this.setState({
      mobileView: true,
    });
  }

  textTransformer(arr) {
    return (<span>{arr[0]}.<span style={{ fontSize: '15px' }}>{arr[1]}</span></span>);
  }

  arrCreator(text) {
    return text.split('.');
  }

  stateOutOfViewHandler() {
    this.setState({
      outOfView: true,
    });
  }

  createMarkup(description) {
    return { __html: description.props.children };
  }

  render() {
    let fixedDescription;
    const fixHiperOferta = 'Precio Especial al comprar un producto marcado así: H';
    let instructionDesc;

    if (this.props.description) {
      if (this.props.description === fixHiperOferta) {
        fixedDescription = (<p>Al comprar 1 producto marcado así <span className="icon-home" /></p>);
      } else {
        fixedDescription = (<p>{this.props.description}</p>);
      }
    } else {
      fixedDescription = '';
    }

    let inactiveInstruction = false;
    if (this.props.instruction) {
      instructionDesc = this.props.instruction;
    } else {
      inactiveInstruction = true;
    }

    let activeModalOutOfView;
    if (this.state.mobileView) {
      activeModalOutOfView = {};
    } else if (this.state.outOfView) {
      activeModalOutOfView = { right: '7px' };
    } else {
      activeModalOutOfView = { left: `${this.props.positionX}px` };
    }
    return (
      <div ref={this.tl} id={this.props.id} style={activeModalOutOfView} className={inactiveInstruction ? 'active-hero-badge-modal no-instruction' : 'active-hero-badge-modal'}>
        <div className="row">
          <div className="columns small-2 large-2"><button style={{ display: 'none' }} className={this.state.outOfView ? 'active-hero-badge-modal__close-btn-left' : 'active-hero-badge-modal__close-btn'} onClick={this.props.handleCloseAllModal}><img alt="Cerrar" width="100%" src="https://storage.googleapis.com/betterware-production-media/content/home/Iconos/icono-delete.svg" /></button></div>
          <div className="columns small-10 large-10 active-hero-badge-modal__modal-content">
            <p className="active-hero-badge-modal__special-price">{this.textTransformer(this.arrCreator(this.props.offerPrice))} {(this.props.badge === '2_o_mas' || this.props.badge === 'oferta_2x_mismo_producto') ? <span style={{ fontSize: '12px', color: '#f96f00' }}> c/u </span> : ''}</p>
            <p dangerouslySetInnerHTML={this.createMarkup(fixedDescription)} />
            {
              instructionDesc &&
              <div>
                <div className="text-dialog">
                  <div className="bubble">
                    <span>{instructionDesc}</span>
                  </div>
                  <div className="pointer" />
                </div>
                <div className="image-container">
                  <img alt="Instrucciones" src="https://storage.googleapis.com/betterware-production-media/content/home/Banner/AVATAR-1.png" />
                  <img alt="Instrucciones" src="https://storage.googleapis.com/betterware-production-media/content/home/Banner/AVATAR-2.png" />
                  <img alt="Instrucciones" src="https://storage.googleapis.com/betterware-production-media/content/home/Banner/AVATAR-3.png" />
                  <img alt="Instrucciones" src="https://storage.googleapis.com/betterware-production-media/content/home/Banner/AVATAR-4.png" />
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

ProductListComponentModal.propTypes = {
  offerPrice: PropTypes.string,
  description: PropTypes.string,
  instruction: PropTypes.string,
  id: PropTypes.string,
  handleCloseAllModal: PropTypes.any,
  modalOutOfBoundHandler: PropTypes.any,
  positionX: PropTypes.number,
  badge: PropTypes.string
};

export default ProductListComponentModal;
