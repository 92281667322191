import React from 'react';
import { render } from 'react-dom';
import ProductListComponentApp from './ProductListComponentApp';

const ProductListComponentRoot = () => (
  <div>
    <ProductListComponentApp products={window.productsListComponent} />
  </div>
);

render(<ProductListComponentRoot />, document.getElementById('productListComponent'));
