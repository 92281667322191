import {
  REQUEST_ADD_CART_ITEM,
  RECEIVE_ADD_CART_ITEM,
  STATUS_CODES,
  ADD_TO_BAG_SUCCESS_MESSAGE_ID,
  OUT_OF_STOCK_ERROR_MESSAGE_ID,
  UPDATE_ADD_TO_CART_QTY_FORM,
  MINIMUM_QTY,
  MAXIMUM_QTY,
  MINIMUM_QTY_REACHED,
  MAXIMUM_QTY_REACHED
} from './addToBagConstants';
import { setStickyMsgID } from 'app/globalMessages/globalMessagesActions';
import * as MSG from 'app/globalMessages/globalMessagesConstants';
import { shouldIncrementTotalEntriesNumber } from 'app/orderPlacement/cart/cartUtils';
import { ajaxRequest } from 'app/utils/helpers';
import 'url-search-params-polyfill';
import { getCookie, setCookie } from 'app/utils/helperCookies';

const { addToBagUrl } = window.inlineCartConfiguration;

const hasError = (json) => (json.statusCode !== 'success');

// QTY Form Actions
export const updateAddToCartQtyForm = (sku, newQty, maxQty = MAXIMUM_QTY) => (dispatch) => {
  dispatch({
    type: UPDATE_ADD_TO_CART_QTY_FORM,
    data: newQty,
    sku
  });

  if (newQty === maxQty) {
    dispatch({
      type: MAXIMUM_QTY_REACHED,
      sku
    });
  } else if (newQty === MINIMUM_QTY) {
    dispatch({
      type: MINIMUM_QTY_REACHED,
      sku
    });
  }
};

export const requestAddCartItem = () => ({
  type: REQUEST_ADD_CART_ITEM
});

export const receiveAddCartItem = (totalEntries) => ({
  type: RECEIVE_ADD_CART_ITEM,
  totalEntries
});

const errorCallback = (json, totalEntries, dispatch) => {
  dispatch(receiveAddCartItem(totalEntries));

  if (json.statusCode === 'lowStock' || (json.errors && json.errors.type === 'InsufficientStockError')) {
    dispatch({
      type: STATUS_CODES.OUT_OF_STOCK,
      entry: json.entry
    });
  } else {
    dispatch({
      type: STATUS_CODES.ERROR,
      entry: json.entry
    });
  }

  dispatch(setStickyMsgID(OUT_OF_STOCK_ERROR_MESSAGE_ID, MSG.TYPE_ERROR, true));
};

const successCallback = (json, totalEntries, dispatch, isAddToCart) => {
  if (hasError(json)) {
    errorCallback(json, totalEntries, dispatch);
    return;
  }

  dispatch({
    type: isAddToCart ? STATUS_CODES.ADDED_TO_BAG : STATUS_CODES.BUY_NOW,
    entry: json.entry,
    data: json
  });

  dispatch(receiveAddCartItem(shouldIncrementTotalEntriesNumber(json) ? totalEntries + 1 : totalEntries));
  dispatch(setStickyMsgID(ADD_TO_BAG_SUCCESS_MESSAGE_ID, MSG.TYPE_SUCCESS, true));
};

export const addProductToBag = (sku, quantity = 1, isAddToCart) => (dispatch, getState) => {
  if (getState().isCartFetching) {
    return null;
  }
  const currentPage = window.location.href;

  if (currentPage !== getCookie('last-page')) {
    const splits = currentPage.split('/');
    const splitIndex = 2;
    if (splits[splits.length - splitIndex] === 'es' || splits[splits.length - splitIndex] === 'shop') {
      setCookie('last-page', currentPage);
    }
  }
  const { totalEntries } = getState();
  const crossSellUrlParameter = new URLSearchParams(window.location.search).get('crossSell');
  const crossSell = crossSellUrlParameter ? decodeURIComponent(crossSellUrlParameter) : '';

  dispatch({ type: isAddToCart ? STATUS_CODES.ADDING_TO_BAG : STATUS_CODES.BUYING_NOW, sku });
  dispatch(requestAddCartItem());

  return ajaxRequest('POST', addToBagUrl, { sku, quantity, crossSell }).then(
    (json) => successCallback(json, totalEntries, dispatch, isAddToCart),
    () => errorCallback(totalEntries, dispatch)
  );
};
