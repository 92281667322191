import { connect } from 'react-redux';
import AddToBag from 'app/commonComponents/addToBag/addToBag';
import { addProductToBag, updateAddToCartQtyForm } from 'app/commonComponents/addToBag/addToBagActions';
import {
  STATUS_CODES,
  DEFAULT_QTY_FORM_STATE
} from './addToBagConstants';

const mapStateToProps = (state, ownProps) => {
  const sku = (ownProps.sku) ? ownProps.sku : state.loadedProduct.sku;
  const qtyFormUpdate = (state.QtyFormUpdate[sku]) ? (state.QtyFormUpdate[sku]) : DEFAULT_QTY_FORM_STATE;
  const maxAllowedQty = qtyFormUpdate.addToCartMax < 0 ? 0 : qtyFormUpdate.addToCartMax;
  const actualQty = qtyFormUpdate.actualQty > maxAllowedQty ? maxAllowedQty : qtyFormUpdate.actualQty;
  const minimumQtyReached = qtyFormUpdate.minimumQtyReached || actualQty <= 1;
  const maximumQtyReached = qtyFormUpdate.maximumQtyReached || actualQty === maxAllowedQty;
  const products = (state.products) ? state.products.items : state.loadedProduct;
  const productsHome = window.productsListComponent;

  return {
    ATBState: (state.ATBState[sku]) ? state.ATBState[sku] : STATUS_CODES.ADD_TO_BAG_READY,
    outOfStock: false,
    sku,
    actualQty,
    maxAllowedQty,
    minimumQtyReached,
    maximumQtyReached,
    products,
    productsHome
  };
};

const mapDispatchToProps = (dispatch) => ({
  onATBClick: (sku, qty, isAddToCart) => {
    dispatch(addProductToBag(sku, qty, isAddToCart));
  },
  onATBStateUpdate: (sku, ATBState) => {
    dispatch({
      type: ATBState,
      sku
    });
  },
  // qty form functions
  onDecreaseQtyClick: (sku, newQty, maxQty) => {
    dispatch(updateAddToCartQtyForm(sku, newQty, maxQty));
  },
  onIncreaseQtyClick: (sku, newQty, minQty) => {
    dispatch(updateAddToCartQtyForm(sku, newQty, minQty));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToBag);
