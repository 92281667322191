import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddToBag from 'app/commonComponents/addToBag/addToBagContainer';
import store from 'app/orderPlacement/orderPlacementStore';
import providerHOC from 'app/utils/providerHOC/providerHOC';
import ProductListComponentHeroBadge from './ProductListComponentHeroBadge';
import Img from './ProductListComponentImage';

const parseNum = 2;
class ProductsDetailsHOC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.url,
      imageSrc: this.props.imageSrc,
      name: this.props.name,
      code: this.props.code,
      price: this.props.price,
      offerPrice: (this.props.offerPrice) ? `$${parseFloat(this.props.offerPrice).toFixed(parseNum)}` : '',
      badge: (this.props.badge) ? this.props.badge : '',
      descriptionBadge: (this.props.descriptionBadge) ? this.props.descriptionBadge : '',
      instructionBadge: (this.props.instructionBadge) ? this.props.instructionBadge : '',
      mainSize: {
        mm: 640,
        m: 320,
        s: 240,
        xs: 120,
      }
    };
    this.textTransformer = this.textTransformer.bind(this);
  }

  textTransformer(arr) {
    return (<span>{arr[0]}.<span style={{ fontSize: '10px' }}>{arr[1]}</span></span>);
  }

  render() {
    const thumborSizes = [];
    Object.entries(this.state.mainSize).forEach((value) => {
      thumborSizes.push(value[1]);
    });
    let specialPriceText;
    const fixedPriceArr = this.state.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').split('.');
    let heroBadge;
    if (this.state.offerPrice !== '') {
      const offerPriceArr = this.state.offerPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').split('.');
      specialPriceText = (<p>{this.textTransformer(fixedPriceArr)} o ll&eacute;valo por <span className="product-list-component__special-price">{this.textTransformer(offerPriceArr)}</span></p>);
    } else {
      specialPriceText = (<p className="product-list-component__regular-price"><strong>{this.textTransformer(fixedPriceArr)}</strong></p>);
    }

    if (this.state.badge) {
      heroBadge = (<ProductListComponentHeroBadge
        handleOpenCloseModal={this.props.handleOpenCloseModal}
        id={this.props.id}
        modal={this.props.modal}
        description={this.state.descriptionBadge}
        instruction={this.state.instructionBadge}
        badge={this.state.badge}
        offerPrice={this.state.offerPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        handleCloseAllModal={this.props.handleCloseAllModal}
        key={`badge-${this.state.code}`}
      />);
    }

    return (
      <div className="columns large-3 medium-4 small-6" >
        <div className={`product-list-component__item ${this.props.modal ? 'glowing-border' : ''}`}>
          <p className="product-list-component__title">{this.state.name}</p>
          <div className="product-list-component__image">
            { this.state.offerPrice && <div> {heroBadge} </div> }
            <a href={this.state.url}>
              <Img
                sizes={['40vw', '30vw', '20vw', '10vw']}
                thumborSizes={thumborSizes}
                alt=""
                imgSrc={this.state.imageSrc}
                cName="lazyload"
                initialSize={300}
              />
            </a>
          </div>
          <p>Código {this.state.code}</p>
          <div className="text-wrapper">
            {specialPriceText}
          </div>
          <AddToBag sku={`V${this.state.code}`} />
        </div>
      </div>
    );
  }
}

ProductsDetailsHOC.propTypes = {
  imageSrc: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.string,
  offerPrice: PropTypes.any,
  url: PropTypes.string,
  code: PropTypes.any,
  badge: PropTypes.string,
  descriptionBadge: PropTypes.string,
  instructionBadge: PropTypes.string,
  id: PropTypes.string,
  handleCloseAllModal: PropTypes.any,
  handleOpenCloseModal: PropTypes.any,
  modal: PropTypes.bool
};

const ProductListComponent = providerHOC(store)(ProductsDetailsHOC);

export default ProductListComponent;
