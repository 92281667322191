import React, { Component, createRef } from 'react';
import ProductListComponentModal from './productListComponentModal';
import PropTypes from 'prop-types';

class ProductListComponentHeroBadge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      positionX: 0,
      modalOutOfBound: false
    };
    this.heroBadgeElement = createRef();
    this.modalOutOfBoundHandler = this.modalOutOfBoundHandler.bind(this);
    this.setPositionXHandler = this.setPositionXHandler.bind(this);
  }

  componentDidMount() {
    const borderValue = 2;
    const positionX = Math.round(this.heroBadgeElement.current.offsetLeft) - borderValue;
    this.setPositionXHandler(positionX);
  }

  setPositionXHandler(position) {
    this.setState({
      positionX: position
    });
  }

  modalOutOfBoundHandler() {
    this.setState({
      modalOutOfBound: !this.state.modalOutOfBound
    });
  }


  render() {
    let modalBadge;
    let modalHeroStyle;

    let showModal = false;
    const productsListHome = window.productsListComponent;

    if (this.props.clickBadgeItem) {
      if (this.props.id === this.props.clickBadgeItem.id) {
        showModal = true;
      }
    }

    const activateModal = (productsListHome ? this.props.modal : showModal);

    if (activateModal) {
      modalBadge = (<ProductListComponentModal
        description={this.props.description}
        instruction={this.props.instruction}
        badge={this.props.badge}
        positionX={this.state.positionX}
        handleCloseAllModal={this.props.handleCloseAllModal}
        offerPrice={this.props.offerPrice}
        id={this.props.id}
        modalOutOfBoundHandler={this.modalOutOfBoundHandler}
      />);
      modalHeroStyle = this.state.modalOutOfBound ? 'hero-badge-border-radius-deactive' : 'hero-badge-border-radius-active';
    } else {
      modalBadge = <div />;
      modalHeroStyle = 'hero-badge-border-radius-deactive';
    }

    return (
      <div>
        <span ref={this.heroBadgeElement} role="button" key={`${this.props.badge}_badge-hero`} className={`product-badge__hero ${modalHeroStyle}`} onClick={() => { if (this.props.description) { this.props.handleOpenCloseModal(this.props.id); } }} tabIndex={0}>VER OFERTA</span>
        {modalBadge}
      </div>
    );
  }
}

ProductListComponentHeroBadge.propTypes = {
  offerPrice: PropTypes.string,
  description: PropTypes.string,
  instruction: PropTypes.string,
  id: PropTypes.string,
  handleCloseAllModal: PropTypes.any,
  handleOpenCloseModal: PropTypes.any,
  badge: PropTypes.string,
  clickBadgeItem: PropTypes.any,
  modal: PropTypes.bool,
};

export default ProductListComponentHeroBadge;
