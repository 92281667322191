import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { STATUS_CODES, RESTORE_ATB_SECONDS, ATB_STATE, RESTORE_MODAL_ADDTOCART } from './addToBagConstants';
import { FormattedMessage } from 'react-intl';
import AddToCartModal from './addToCartModal/addToCartModal';

const { baseCheckoutUrl } = window.inlineCartConfiguration;

class AddToBag extends Component {
  constructor(props) {
    const {
      outOfStock,
      count,
      maxAllowedQty,
      ATBState
    } = props;

    super();
    this.state = {
      code: this.getStatus(outOfStock, count, maxAllowedQty, ATBState),
      isModalOpen: false,
      nameProduct: '',
      nameImage: '',
      sku: ''
    };

    this.onModalCancel = this.onModalCancel.bind(this);
    this.timecloseModal = this.timecloseModal.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const {
      outOfStock,
      count,
      maxAllowedQty,
      ATBState
    } = newProps;

    if (this.state.code === STATUS_CODES.ADDING_TO_BAG && ATBState === STATUS_CODES.ADDED_TO_BAG) {
      this.setState({
        code: STATUS_CODES.ADDED_TO_BAG,
        isModalOpen: true
      });
      this.restoreATBButtons(true);
      return;
    } else if (this.state.code === STATUS_CODES.BUYING_NOW && ATBState === STATUS_CODES.BUY_NOW) {
      this.setState({ code: STATUS_CODES.BUY_NOW });
      this.restoreATBButtons(false);
      window.location.href = baseCheckoutUrl;
    }
    this.setState({ code: this.getStatus(outOfStock, count, maxAllowedQty, ATBState) });
  }

  componentDidUpdate() {
    this.timecloseModal();
  }

  onModalCancel() {
    this.setState({
      isModalOpen: false
    });
  }

  getStatus(outOfStock, count, maxAllowedQty, ATBState) {
    if (outOfStock) {
      return STATUS_CODES.OUT_OF_STOCK;
    }

    if (count <= 0) {
      return STATUS_CODES.ADD_TO_BAG_DISABLED;
    }

    if (maxAllowedQty <= 0) {
      return STATUS_CODES.MAX_QTY_IN_CART_REACHED;
    }

    return ATBState;
  }

  restoreATBButtons(isAddToCart) {
    setTimeout(
      () => this.props.onATBStateUpdate(this.props.sku, (isAddToCart ? STATUS_CODES.ADD_TO_BAG_READY : STATUS_CODES.BUY_NOW_READY)),
      RESTORE_ATB_SECONDS);
  }

  timecloseModal() {
    setTimeout(
      () => this.onModalCancel(),
      RESTORE_MODAL_ADDTOCART);
  }

  render() {
    const {
      sku,
      onATBClick,
      onDecreaseQtyClick,
      onIncreaseQtyClick,
      actualQty,
      maxAllowedQty,
      minimumQtyReached,
      maximumQtyReached,
      products,
      productsHome,
    } = this.props;

    const { code } = this.state;
    let buttonState;
    let buttonStateBuyNow;
    if (code === 'ADD_TO_BAG_READY') {
      buttonState = ATB_STATE[code];
    } else {
      buttonStateBuyNow = ATB_STATE[code];
    }

    return (
      <div>
        <div className="add-to-bag-container">
          <div className={code === 'ADD_TO_BAG_READY' ? `add-to-bag-wrapper ${buttonState.classNameATB}` : 'add-to-bag-wrapper'}>
            <div className="add-to-bag-form-wrapper">
              <div className="add-to-bag-form">
                <button
                  className="add-to-bag-form-decrease"
                  onClick={() => onDecreaseQtyClick(sku, actualQty - 1, maxAllowedQty)}
                  disabled={minimumQtyReached ? 'disabled' : ''}
                >-
                </button>
                <span>{ actualQty }</span>
                <button
                  className="add-to-bag-form-increase"
                  onClick={() => onIncreaseQtyClick(sku, actualQty + 1, maxAllowedQty)}
                  disabled={maximumQtyReached ? 'disabled' : ''}
                >+
                </button>
              </div>
            </div>
            <div className={code === 'ADD_TO_BAG_READY' ? `add-to-bag ${buttonState.classNameATB}` : 'add-to-bag'}>
              <button
                className="add-to-bag-button button"
                onClick={() => {
                  onATBClick(sku, actualQty, true);
                  const skuAdd = sku.split('V');
                  let productFiltered;
                  if (!productsHome) {
                    if (!products.name) {
                      productFiltered = products.filter((product) => {
                        const productsItem = product.id === skuAdd[1];
                        return productsItem;
                      });
                    }
                  }
                  if (!productsHome) {
                    this.setState({
                      nameProduct: products.name ? products.name : productFiltered[0].name,
                      nameImage: products.name ? products.imageSet.items[0].originalSrc : productFiltered[0].heroImage,
                      sku: skuAdd[1]
                    });
                  } else {
                    productFiltered = productsHome.filter((product) => {
                      const productsItem = product.code === skuAdd[1];
                      return productsItem;
                    });
                    this.setState({
                      nameProduct: productFiltered[0].name,
                      nameImage: productFiltered[0].image,
                      sku: skuAdd[1]
                    });
                  }
                }}
                disabled={code === 'ADD_TO_BAG_READY' ? buttonState.disabled : ''}
                title="Añadir a carrito"
              >
                <span className="add-to-bag-button-text">
                  <span className={code === 'ADD_TO_BAG_READY' ? `add-to-bag-button-icon ${buttonState.icon}` : 'add-to-bag-button-icon icon-cart'} />
                </span>
              </button>
              <span className="add-to-bag-error-text"> {code === 'ADD_TO_BAG_READY' ? buttonState.errorText : ''} </span>
              <div className={code === 'ADD_TO_BAG_READY' ? `add-to-bag-text-no-stock ${buttonState.classNameATB}` : `add-to-bag-text-no-stock ${buttonStateBuyNow.classNameATB}`}>
                <FormattedMessage id="products.cta.addToBag.outOfStock" />
              </div>
              <div className={code === 'ADD_TO_BAG_READY' ? `add-to-bag-max-qty ${buttonState.classNameATB}` : `add-to-bag-max-qty ${buttonStateBuyNow.classNameATB}`}>
                <FormattedMessage id="products.cta.addToBag.maxQtyReached" />
              </div>
            </div>
            <div className="buy-now _is-buy_now-ready">
              <button
                className="buy-now-button button"
                onClick={() => {
                  onATBClick(sku, actualQty, false);
                }}
                title="Compra ahora"
              >
                <span className="buy-now-button-text">
                  <img className="buy-now-button-icon" src="https://storage.googleapis.com/betterware-production-media/content/home/Iconos/DINERO-.svg" alt="Comprar ahora" />
                </span>
              </button>
            </div>
          </div>
        </div>
        <AddToCartModal
          isModalOpen={this.state.isModalOpen}
          maxWidth={500}
          onModalCancel={this.onModalCancel}
          nameProduct={this.state.nameProduct}
          nameImage={this.state.nameImage}
          sku={this.state.sku}
        />
      </div>
    );
  }
}

AddToBag.propTypes = {
  onATBClick: PropTypes.func.isRequired,
  onDecreaseQtyClick: PropTypes.func.isRequired,
  onIncreaseQtyClick: PropTypes.func.isRequired,
  onATBStateUpdate: PropTypes.func.isRequired,
  ATBState: PropTypes.string,
  sku: PropTypes.string,
  count: PropTypes.number,
  actualQty: PropTypes.number,
  outOfStock: PropTypes.bool,
  maxAllowedQty: PropTypes.number,
  minimumQtyReached: PropTypes.bool,
  maximumQtyReached: PropTypes.bool,
  products: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  productsHome: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
};

export default AddToBag;
