import React, { Component } from 'react';
import ProductListComponent from './ProductListComponent';
import PropTypes from 'prop-types';

const breakNumber = 6;
class ProductListComponentApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      badgeState: [],
    };
    this.handleOpenCloseModal = this.handleOpenCloseModal.bind(this);
    this.handleCloseAllModal = this.handleCloseAllModal.bind(this);
  }

  componentWillMount() {
    const allBadges = [];
    this.props.products.forEach((product, index) => {
      const newBadge = {
        id: `${product.name} ${index}`,
        modal: false,
      };
      allBadges.push(newBadge);
    });

    this.setState({
      badgeState: allBadges
    });
  }

  handleOpenCloseModal(id) {
    const indexFinder = this.state.badgeState.map(e => e.id).indexOf(id);
    const arrayToChange = [...this.state.badgeState];
    for (let i = 0; i < arrayToChange.length; i++) {
      if (i === indexFinder) {
        arrayToChange[i].modal = !arrayToChange[i].modal;
      } else {
        arrayToChange[i].modal = false;
      }
    }

    this.setState({
      badgeState: arrayToChange
    });
  }

  handleCloseAllModal() {
    const arrayToChange = [...this.state.badgeState];
    for (let i = 0; i < arrayToChange.length; i++) {
      arrayToChange[i].modal = false;
    }
    this.setState({
      badgeState: arrayToChange
    });
  }
  render() {
    const productWrapperArr = [[], []];
    this.props.products.forEach((product, index) => {
      if (index < breakNumber) {
        productWrapperArr[0].push(<ProductListComponent
          imageSrc={product.image}
          name={product.name}
          code={product.code}
          price={`$${product.price}`}
          offerPrice={product.offerPrice}
          key={`${product.name} ${product.code}`}
          url={product.url}
          badge={product.badge}
          descriptionBadge={product.descriptionBadge}
          instructionBadge={product.instructionBadge}
          modal={this.state.badgeState[index].modal}
          id={`${product.name} ${index}`}
          handleOpenCloseModal={this.handleOpenCloseModal}
          handleCloseAllModal={this.handleCloseAllModal}
        />);
      } else {
        productWrapperArr[1].push(<ProductListComponent
          imageSrc={product.image}
          name={product.name}
          code={product.code}
          price={`$${product.price}`}
          offerPrice={product.offerPrice}
          key={`${product.name} ${product.code}`}
          url={product.url}
          badge={product.badge}
          descriptionBadge={product.descriptionBadge}
          instructionBadge={product.instructionBadge}
          modal={this.state.badgeState[index].modal}
          id={`${product.name} ${index}`}
          handleOpenCloseModal={this.handleOpenCloseModal}
          handleCloseAllModal={this.handleCloseAllModal}
        />);
      }
    }
    );
    return (
      <div className="columns large-12">
        <div className="row">
          <div className="product-list-component">
            {productWrapperArr[0]}
            <div className="product-list-component-medium-row">
              {productWrapperArr[1]}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProductListComponentApp.propTypes = {
  products: PropTypes.any
};

export default ProductListComponentApp;
